.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-x: hidden;
}

.searchSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* max-width: 1440px; */
  width: 100%;
  background: #F8F8F8;
}

.searchSection__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 25px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  font-size: 16px;
  font-family: "R-Medium";
}

.top__action {
  width: 100%;
  max-width: 314px;
}

.top__view {
  width: 100%;
  max-width: 351px;
}

.top__search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 646px;
}

.top__flag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 102px;
  width: 100%;
  cursor: pointer;
}

.flag__text {
  font-size: 14px;
  font-family: "R";
}

.arrowDown {
  background-repeat: no-repeat;
  background: url('./assets/arrowDown.svg');
  width: 10px;
  height: 5px;
}

.arrowUp {
  background-repeat: no-repeat;
  background: url('./assets/arrowUp.svg');
  width: 10px;
  height: 5px;
}

.searchSection__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px 10px 13px 25px;
  font-size: 12px;
  font-family: "R"; 
}

.bottom__action {
  width: 100%;
  max-width: 314px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 16px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.action__buttonOn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 18px;
  color: #000000;
  border: 1px solid #39BA42;
  border-radius: 3px;
  height: 36px;
  width: 100%;
  max-width: 123px;
  cursor: pointer;
}

.action__buttonOn:hover {
  background: rgba(57, 186, 66, 0.1);
} 

.action__buttonOff {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;
  color: #000000;
  border: 1px solid #EE0064;
  border-radius: 3px;
  height: 36px;
  width: 100%;
  max-width: 149px;
  cursor: pointer;
}

.action__buttonOff:hover {
  background: rgba(238, 0, 100, 0.1);
} 

.bottom__view {
  width: 100%;
  max-width: 351px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 16px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.bottom__view div{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #6200EE;
  border-radius: 3px;
  height: 36px;
}

.bottom__view div:hover{
  background: rgba(98, 0, 238, 0.1);
}

.view__full {
  max-width: 72px;
}

.view__credits {
  max-width: 97px;
}

.view__alfa{
  max-width: 130px;
}

.bottom__search {
  width: 100%;
  max-width: 661px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 16px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  height: 74px;
}

.searchSelect {
  width: 100%;
  max-width: 196px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 36px;
  overflow: visible;
}

.search__target {
  width: 100%;
  max-width: 270px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.target__text {
  width: 100%;
  max-width: 58px;
  font-size: 12px;
  font-family: "R-Medium";
}

.target__input {
  width: 100%;
  max-width: 196px;
  display: flex;
  align-items: center;
  background: #ffffff;
  cursor: pointer;
  margin-bottom: 2px;

}

.input {
  width: 100%;
  max-width: 162px;
  height: 36px;
  padding: 0 12px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-right: none;
}

.input__arrow {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 34px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-left: none;
  height: 36px;
  background: url('./assets/arrowInput.svg');
  background-repeat: no-repeat;
  background-position-y: center;
}

.target__list{
  width: 100%;
  max-width: 110px;
  height: 100px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  z-index: 10;
  background: #ffffff;
}

.selectList__item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 110px;
  height: 24px;
  cursor: pointer;
}

.selectList__item:hover {
  background: #EFE6FD;
}

.search__value {
  width: 100%;
  max-width: 215px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.value__text {
  width: 100%;
  max-width: 61px;
  font-size: 12px;
  font-family: "R-Medium";
}

.value__input {
  width: 100%;
  max-width: 138px;
  height: 28px;
  padding: 0 12px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.search__button {
  width: 100%;
  max-width: 125px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search__plus {
  width: 19px;
  height: 19px;
  background: url('./assets/plus.svg');
  background-repeat: no-repeat;
  /* background-size: cover; */
}

.buttonSearch {
  background: #6200EE;
  border-radius: 4px;
  width: 100%;
  max-width: 100px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 0 12px;
  font-size: 12px;
  font-family: "R-Medium";
  color: #ffffff;
}

.buttonSearch__icon {
  width: 13px;
  height: 14px;
  background: url('./assets/search.svg');
  background-repeat: no-repeat;
}

.dateSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* max-width: 1440px; */
  width: 100%;
  background: #F8F8F8;
}

.dateSection__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 25px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  font-size: 16px;
  font-family: "R-Medium";
}

.bottom__dateFilter {
  width: 100%;
  max-width: 648px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 16px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.inputCalendarStart {
  background: url('./assets/arrowInput.svg');
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 93px;
  cursor: pointer;
}

.buttonDate {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  border: 1px solid #6200EE;
  border-radius: 3px;
  cursor: pointer;
  font-family: "R";
  font-size: 12px;
}

.buttonDate:hover {
  background: rgba(98, 0, 238, 0.1);
}

.buttonActivePurple {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  border: 1px solid #6200EE;
  border-radius: 3px;
  cursor: pointer;
  font-family: "R";
  font-size: 12px;
  color: #EFE6FD;
  background: #6200EE;
}

.statSection {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding: 20px 0 0 24px;
  margin-bottom: 20px;

}

.stat {
  display: flex;
  flex-direction: column;
}

.stat__label {
  font-size: 16px;
  font-family: "R-Bold";
  margin-bottom: 15px;
}

.stat__text {
  display: flex;
  max-width: 950px;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 20px;
  font-size: 16px;
  font-family: "R";
}

.stat__item {
  display: flex;
}

.stat__item span {
  font-family: "R-Medium";
  margin-left: 6px;
}

.paginationSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 610px;
  width: 100%;
  font-family: "R-Medium";
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  margin-right: 44px;
}

.pagination__text {
  max-width: 190px;
  width: 100%;
}

.pagination__startActive {
  transform: rotate(180deg);
  width: 15px;
  height: 15px;
  background: url('./assets/paginationLast.svg');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

}

.pagination__start {
  width: 15px;
  height: 15px;
  background: url('./assets/paginationStart.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.pagination__prevActive {
  transform: rotate(180deg);
  width: 15px;
  height: 15px;
  background: url('./assets/paginationNext.svg');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.pagination__prev {
  width: 15px;
  height: 15px;
  background: url('./assets/paginationPrev.svg');
  background-repeat: no-repeat;
  background-position: center;

}

.pagination__current {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 105px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  height: 56px;
  padding-right: 16px;
  padding-left: 16px;
}

.pagination__nextActive {
  width: 15px;
  height: 15px;
  background: url('./assets/paginationNext.svg');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

}

.pagination__next {
  transform: rotate(180deg);
  width: 15px;
  height: 15px;
  background: url('./assets/paginationPrev.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.pagination__lastActive {
  width: 15px;
  height: 15px;
  background: url('./assets/paginationLast.svg');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

}

.pagination__last {
  transform: rotate(180deg);
  width: 15px;
  height: 15px;
  background: url('./assets/paginationStart.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.pageList {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 60px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background: #ffffff;
  top: 56px;
  left: 21px;
  z-index: 16;
}

.pageList__item {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
}

.pageList__itemCurrent {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(98, 0, 238);
}

.pageList__item:hover {
  background: #F0F8FF;
}

.preload {
  background: url('./assets/preloader.svg');
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 300px;
}