.wrapper {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding: 0 0 0 24px;
  overflow-x: scroll;
  width: 100%;
  height: auto;
  min-height: 300px;
}

table {
  max-height: 553px;
  
  border-left: 1px solid rgba(0, 0, 0, 0.12);


  display: grid;
  /* border-collapse: collapse; */
  /* max-width: 2025px; */
  /* width: 100%; */
  /* grid-template-columns: 
    minmax(50px, 1fr)
    minmax(70px, 1fr)
    minmax(59px, 1fr)
    minmax(66px, 1fr)
    minmax(93px, 1fr)
    minmax(83px, 1fr)
    minmax(67px, 1fr)
    minmax(73px, 1fr)
    minmax(77px, 1fr)
    minmax(102px, 1fr)
    minmax(80px, 1fr)
    minmax(130px, 1fr)
    minmax(188px, 1fr)
    minmax(153px, 1fr)
    minmax(188px, 1fr)
    minmax(153px, 1fr)
    minmax(120px, 1fr)
    minmax(88px, 1fr)
    minmax(92px, 1fr)
    minmax(85px, 1fr); */
    /* overflow-y: hidden; */
}

.adminTable {
  grid-template-columns: 
    minmax(50px, 1fr)
    minmax(70px, 1fr)
    minmax(59px, 1fr)
    minmax(66px, 1fr)
    minmax(93px, 1fr)
    minmax(100px, 1fr)
    minmax(67px, 1fr)
    minmax(73px, 1fr)
    minmax(77px, 1fr)
    minmax(102px, 1fr)
    minmax(80px, 1fr)
    minmax(150px, 1fr)
    minmax(260px, 1fr)
    minmax(170px, 1fr)
    minmax(260px, 1fr)
    minmax(170px, 1fr)
    minmax(120px, 1fr)
    minmax(88px, 1fr)
    minmax(92px, 1fr)
    minmax(85px, 1fr)
}

.managerAlphaTable {
  grid-template-columns: 
    minmax(50px, 1fr)
    minmax(70px, 1fr)
    minmax(59px, 1fr)
    minmax(66px, 1fr)
    minmax(93px, 1fr)
    minmax(150px, 1fr)
    minmax(260px, 1fr)
    minmax(170px, 1fr)
}

.managerKcTable {
  grid-template-columns: 
    minmax(50px, 1fr)
    minmax(70px, 1fr)
    minmax(59px, 1fr)
    minmax(66px, 1fr)
    minmax(93px, 1fr)
    minmax(100px, 1fr)
    minmax(67px, 1fr)
    minmax(73px, 1fr)
    minmax(77px, 1fr)
    minmax(102px, 1fr)
    minmax(80px, 1fr)
    minmax(260px, 1fr)
    minmax(170px, 1fr)
}

thead,
tbody,
tr {
  display: contents;
  /* overflow-y: visible; */
}

th,
td {
  /* overflow: hidden; */
  text-overflow: ellipsis;
  height: 41px;
  /* white-space: nowrap; */

}

th {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  text-align: center;
  font-size: 12px;
  font-family: "R-Medium";
  color: #000000;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #ffffff;
z-index: 15;

}

th:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

td {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  text-align: center;
  font-size: 12px;
  font-family: "R";
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  overflow-y: visible;
  

  /* white-space: nowrap; */

}

.work {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  width: 100%;
}

.workList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 50%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  position: absolute;
  top: 17px;
  left: 11px;
}

.workList__item {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 100%;
  height: 24px;
  background-color: #ffffff ;
  z-index: 10;
  cursor: pointer;
}

.workList__item:hover {
  background-color: #EFE6FD ;
}

.region {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  width: 100%;
  padding: 0 24px 0 6px;
  text-align: center;
  background: url('../Mainpage/assets/arrowInput.svg');
  background-size: 8px 4px;
  background-position-y: center;
  background-position-y: center;
  background-position-x: 80%;
  background-repeat: no-repeat !important;
  cursor: pointer;
}

.regionList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* max-width: 50%; */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  position: absolute;
  top: 27px;
  /* left: 16px; */
  z-index: 10;
  background-color: #ffffff ;


}

.regionList__item {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 100%;
  height: 30px;
  background-color: #ffffff ;
  z-index: 10;
  cursor: pointer;
  padding: 2px 5px;
}

.regionList__item + .regionList__item {
  margin-top: 7px;

}

.regionList__item:hover {
  background-color: #EFE6FD ;
}

.currentWork {
  cursor: pointer;
  background-repeat: no-repeat !important;
  background: url('../Mainpage/assets/arrowInput.svg');
  background-size: 8px 4px;
  padding-left: 10px;
  padding-right: 30px;
  background-position-y: center;
  background-position-y: center;
  background-position-x: 65%;
}

.currentRegion {
  cursor: pointer;
}

.halva__button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 82px;
  height: 24px; */
  width: 100px;
  height: 28px;
  color: #ffffff;
  background: #6200EE;
  border-radius: 3px;
  cursor: pointer;
}

.halva__text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #39BA42;
}

.alphaBank {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alphaYes {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 24px;
  border: 1px solid rgba(57, 186, 66, 0.5);
  border-radius: 3px;
  margin-right: 3px;
  color: #000000;
  cursor: pointer;
}

.alphaNo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 24px;
  border: 1px solid rgba(238, 0, 100, 0.5);
  border-radius: 3px;
  color: #000000;
  cursor: pointer;

}

.alphaStatus {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.alphaNew {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFA59B;
border-radius: 16px;
max-width: 50px;
width: 100%;
cursor: pointer;
margin-right: 5px;
}

.alphaSend {
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFCA7C;
  border-radius: 16px;
  max-width: 77px;
  width: 100%;
cursor: pointer;
margin-right: 5px;

}

.alphaGo {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6FFF7A;
  border-radius: 16px;
  max-width: 75px;
  width: 100%;
cursor: pointer;
}

.alphaAction {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alphaActionOk {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80px;
  height: 24px;
  width: 100%;
  background: #39BA42;
  color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 7px;
}

.alphaActionNotOk {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80px;
  height: 24px;
  width: 100%;
  background: #EE0064;
  color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
}

.alphaOkImg {
  background: url('./assets/Ok.svg');
  background-repeat: no-repeat;
  width: 6px;
  height: 6px;
  margin-right: 4px;
}

.alphaNotOkImg {
  background: url('./assets/cross.svg');
  background-repeat: no-repeat;
  width: 6px;
  height: 6px;
  margin-right: 4px;
}

.tgLogo {
  width: 17px;
  height: 16px;
  background-repeat: no-repeat;
  background: url('./assets/tg.svg');
  margin-right: 3px;

}

.vbLogo {
  width: 17px;
  height: 16px;
  background-repeat: no-repeat;
  background: url('./assets/vb.svg');
  margin-right: 3px;

  
}

.waLogo {
  width: 17px;
  height: 16px;
  background-repeat: no-repeat;
  background: url('./assets/wa.svg');
  margin-right: 3px;

}

.noLogo {
  display: none;
}

.alphaWhere {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  width: 100%;
}

.currentAlpha {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 100%;
  border: 1px solid #6200EE;
  border-radius: 3px;
}

.currentAlpha__text {
  margin-right: 5px;
}

.currentAlpha__arrow {
  width: 10px;
  height: 5px;
  background-repeat: no-repeat;
  background: url('./assets/arrow.svg');
}

.alphaList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  position: absolute;
  top: 25px;
  left: 0px;
}

.alphaList__item {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 100%;
  height: 24px;
  background-color: #ffffff ;
  z-index: 10;
  cursor: pointer;
}

.alphaList__item:hover {
  background-color: #EFE6FD ;
}

.halva__no {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 82px;
  height: 24px;
  color: #ffffff;
  background: #8E8E8E;
  border-radius: 3px;
}

.alphaSend__no {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(33, 33, 33, 0.08);
  border-radius: 16px;
  max-width: 77px;
  width: 100%;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  margin-right: 5px;
}

.alphaSend__no:hover {
  color: rgba(0, 0, 0, 0.87);
  background: rgba(33, 33, 33, 0.08);
  border: 1px solid #AEAEAE;

}

.alphaNew__no {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FBE9E7;
  border-radius: 16px;
  max-width: 50px;
  width: 100%;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  margin-right: 5px;
}

.alphaNew__no:hover {
  color: rgba(0, 0, 0, 0.87);
  background: #FBE9E7;
  border: 1px solid #AEAEAE;
}

.alphaGo__no {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #E8F5E9;
  border-radius: 16px;
  max-width: 75px;
  width: 100%;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
}

.alphaGo__no:hover {
  color: rgba(0, 0, 0, 0.87);
  background: #E8F5E9;
  border: 1px solid #AEAEAE;
}

.alphaActionOk__no {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80px;
  height: 24px;
  width: 100%;
  background: #8E8E8E;
  color: #ffffff;
  border-radius: 3px;
  margin-right: 7px;
}

.alphaActionNotOk__no {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80px;
  height: 24px;
  width: 100%;
  background: #8E8E8E;
  color: #ffffff;
  border-radius: 3px;
}

/* .regionListTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  position: absolute;
  z-index: 10;
  background-color: #ffffff ;
  top: -145px;
} */