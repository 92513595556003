.wrapper {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  /* max-width: 1440px; */
  height: 56px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #30078B;
  color: #ffffff;
  padding: 0 24px;
}

.left {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-family: "R-Medium";
}

.right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 900px;
  width: 100%;
  font-size: 14px;
  font-family: "R";
}

.right span {
  font-family: "R-Bold";
}

.account {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: 200px;
}

.account__img {
  width: 25px;
  height: 25px;
  background: #000000;
  background-repeat: no-repeat;
  border-radius: 20px;
  margin-right: 15px;
}