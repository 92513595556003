.wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 375px;
  /* height: 315px; */
}

.label {
  font-size: 30px;
  margin-bottom: 20px;
  font-family: "R";
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
  /* max-width: 350px; */
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.row {
  display: flex;
  justify-content: center;
  width: 100%;
}

.field__login input{
  background: url(./assets/mail.svg) no-repeat 15px 15px;
}

.field__password input{
  background: url(./assets/lock.svg) no-repeat 14px 14px;
}

.input {
  width: 100%;
  max-width: 326px;
  height: 50px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #BABABA;
  padding: 0 15px 0 50px;
  font-size: 14px;
  font-family: "R";

}

.input:focus {
  outline: none;
  border: 1px solid #202020;
}

.inputError {
  width: 100%;
  max-width: 326px;
  height: 50px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #FF4B4B;
  padding: 0 15px 0 50px;
  font-size: 14px;
  font-family: "R";

}

.inputError:focus {
  outline: none;
  border: 1px solid #FF4B4B;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center ;
  width: 100%;
}

.login__button {
  width: 100%;
  max-width: 326px;
  height: 50px;
  background: #6200EE;
  border-radius: 4px;
  border: none;
  color: #FFFFFF;
  font-size: 16px;
  font-family: "R-Bold";

}

.bottom__text {
  max-width: 295px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: "R-Medium";
}

.bottom__text span {
  color: #6200EE;
  font-family: "R-Bold";

}